<template>
  <div class="power-reserve">
    <container>
      <VHeader/>

      <div class="d-flex justify-center align-center pos-r">
        <h1 class="h1">Расчетный запас хода</h1>
        <a @click="modelAlert">
          <svg-icon
              class="alert-circle pointer pos-r"
              name="alert-circle"
          ></svg-icon>
        </a>
        <div v-if="isAlert" class="alert pos-a">
          <div class="pos-r">
            <a @click="modelAlert">
              <svg-icon
                  class="pos-a cross pointer"
                  height="24"
                  name="x"
                  width="24"
              ></svg-icon>
            </a>
            <p class="alert__text">
              Оцените запас хода для вашей поездки, воспользовавшись
              калькулятором, учитывающим различные условия эксплуатации:
              комплектацию, характер движения, погоду и прочие факторы.
            </p>
          </div>
        </div>
      </div>

      <div class="speed pos-r d-flex justify-center">
        <div class="speed__wrapper pos-r">
          <h1 id="km" class="speed__numbers">314</h1>
          <p class="speed__letters pos-a">КМ</p>
        </div>
        <img
            :src="
            wheels.ct
              ? require(`@/assets/img/porsche/${select}/CT/${select}CT_${wheels.text}.webp`)
              : require(`@/assets/img/porsche/${select}/${select}_${wheels.text}.webp`)
          "
            alt="porsche"
            class="speed__image pos-a"
        />
      </div>

      <h2 class="h2 h2-m text-center">Модель и комплектация</h2>

      <div class="d-flex justify-center">
        <div
            class="
            btn btn-dropdown-menu
            d-flex
            justify-between
            align-center
            pos-r
          "
            @click="setSelected"
        >
          <vue-picker v-model="select" autofocus @close="closeModel">
            <vue-picker-option
                v-for="i in selectItem"
                :key="i.value"
                :value="i.value"
                v-html="i.svg"
            />
          </vue-picker>
          <svg-icon
              :name="isSelected ? 'arrow-up' : 'arrow-down'"
              class="arrow pos-a"
              height="10"
              width="48"
          />
        </div>

        <div
            class="btn btn-cross-turismo d-flex justify-between pointer pos-r"
            @click="setCheck"
        >
          <span>Cross Turismo</span>
          <svg-icon
              :name="isChecked ? 'checkbox' : 'checkbox-empty'"
              class="btn-cross-turismo__arrow"
          />
        </div>
      </div>
    </container>
    <div ref="videoSlider" class="embla">
      <div class="embla__container">
        <div
            v-for="(i, id) in sliderItem"
            :key="i.text"
            class="embla__slide"
            @click="onSlideClick(id, i.img)"
        >
          <div class="embla__slide__inner d-flex align-center pointer">
            <img
                :src="
                i.ct
                  ? require(`@/assets/img/porsche/${select}/wheels/CT/${i.img}.png`)
                  : require(`@/assets/img/porsche/${select}/wheels/${i.img}.png`)
              "
                class="embla__slide__img"
            />
            <div class="embla__slide__text">
              <span v-html="i.text"></span>
            </div>
            <div ref="check_mark" class="check_mark pos-a d-none">
              <svg-icon class="check_mark__svg" name="check_mark"/>
            </div>
          </div>
        </div>
      </div>
    </div>

    <container>
      <div class="destination-calculator-wrap">
        <h2>Условия поездки</h2>

        <div class="d-calculator-settings-wrap">
          <div class="temperature-wrap settings-item">
            <h3>Температура окружающего воздуха</h3>

            <div class="temperature-slider-wrap">
              <div id="temperature-slider">
                <div class="temperature-points-wrap">
                  <div class="temperature-points__item"></div>
                  <div class="temperature-points__item"></div>
                  <div class="temperature-points__item"></div>
                  <div class="temperature-points__item"></div>
                  <div class="temperature-points__item"></div>
                  <div class="temperature-points__item"></div>
                  <div class="temperature-points__item"></div>
                  <div class="temperature-points__item"></div>
                  <div class="temperature-points__item"></div>
                  <div class="temperature-points__item"></div>
                  <div class="temperature-points__item"></div>
                  <div class="temperature-points__item"></div>
                </div>
              </div>

              <div id="slider-start" class="slider-start">-20°</div>

              <div id="slider-end" class="slider-end">35°</div>

            </div>
          </div>
          <div class="conditioner-wrap settings-item">
            <h3>Кондиционирование салона</h3>
            <div class="conditioner-wrap">
              <div
                  class="conditioner-bt d-calculate active"
                  data-conditioner="on"
              >
                AC
              </div>
              <div class="conditioner-bt d-calculate" data-conditioner="eco">
                Eco Plus
              </div>
              <div class="conditioner-bt d-calculate" data-conditioner="off">
                Off
              </div>
            </div>
          </div>
        </div>

        <div class="d-calculator-relief-wrap">
          <div class="r-title">
            <div id="r-city"></div>
            <div id="r-country"></div>
            <div id="r-highway"></div>
          </div>

          <div class="r-images-wrap">
            <div class="r-1-image"></div>
            <div class="r-2-image"></div>
            <div class="r-3-image"></div>
          </div>

          <div id="relief-slider"></div>
        </div>

        <div class="d-calculator d-none">
          <input id="cross-turismo-inf" :value="isChecked"/><br/>
          <input id="car-type" :value="select"/><br/>
          <input id="car-tyre" :value="wheels.text"/><br/>
          <input id="car-conditioner" value="on"/><br/>
          <input id="car-temp" value="20"/><br/>

          <input id="car-city" value="30"/><br/>
          <input id="car-countryroad" value="40"/><br/>
          <input id="car-highway" value="30"/><br/>

          <button id="getkm"></button>
        </div>
      </div>
    </container>
  </div>
</template>

<script>
import Container from "@/components/Container/Container";
import SvgIcon from "../components/IconBase";
import VHeader from "../components/VHeader";
import {VuePicker, VuePickerOption} from "@invisiburu/vue-picker";
import EmblaCarousel from "embla-carousel";
import {ConditionsOfTravel} from "../functions/ConditionsOfTravel";
import {getSelectItem, getSliderItem} from "@/data";

export default {
  name: "VPowerReserve",
  data: function () {
    return {
      isChecked: false,
      isAlert: false,
      isSelected: false,
      select: "taycan",
      selectItem: [],
      sliderItem: [],
      wheelsData: [],
      wheels: {text: '21_1', ct: false},
      embla: null,
      temp: [],
      isSrcImg: false
    };
  },
  methods: {
    closeModel(e) {
      if (e)
        this.isSelected = false
    },

    removeMark() {
      this.$refs.check_mark.forEach((item) => {
        item.classList.add("d-none");
      });
    },

    setCheck() {
      this.isChecked = !this.isChecked;
      this.wheelsData.forEach((item) => {
        if (this.isChecked) {
          if (item.model === this.select + "CT") {
            this.sliderItem = item.wheels;
          }
        } else {
          if (item.model === this.select) {
            this.sliderItem = item.wheels;
          }
        }
      });
      this.resetSlider();
      this.removeMark();
    },

    resetSlider() {
      setTimeout(() => {
        this.$refs.check_mark[0].classList.remove("d-none");
        this.wheels.text = this.sliderItem[0].img;
        this.wheels.ct = this.isChecked
        this.embla.scrollTo(0);
        this.embla.reInit();
      }, 0);
    },

    modelAlert() {
      this.isAlert = !this.isAlert;
    },
    setSelected() {
      this.isSelected = !this.isSelected;
    },

    onSlideClick(index, wheel) {
      if (this.embla.clickAllowed) {
        this.removeMark();
        this.$refs.check_mark[index].classList.remove("d-none");
        this.embla.scrollTo(index);
        this.wheels.text = wheel;
      }
    },
  },
  watch: {
    select() {
      this.isChecked = false;
      this.sliderItem = [];
      this.wheels.text = "21_1"; // test
      setTimeout(() => {
        this.wheelsData.forEach((item) => {
          if (item.model === this.select) {
            this.wheels.text = item.wheels[0].img;
            this.sliderItem = item.wheels;
          }
        });
      }, 0);

      this.removeMark();
      this.resetSlider();
    },
    temp(val) {
      this.wheelsData = val;
      val.forEach((item) => {
        if (this.select === item.model) {
          this.sliderItem = item.wheels;
        }
      });

      setTimeout(() => {
        this.embla = EmblaCarousel(this.$refs.videoSlider, {
          dragFree: true,
          containScroll: "keepSnaps",
          align: "center",
          skipSnaps: false,
        });
        this.resetSlider();
      }, 0);
    },
  },

  mounted() {
    ConditionsOfTravel();
  },

  created() {
    this.selectItem = getSelectItem();
    this.temp = getSliderItem();
  },
  components: {SvgIcon, Container, VHeader, VuePicker, VuePickerOption},
};
</script>

<style>
.temperature-points-wrap {
  position: absolute;
  width: 100%;
  height: 8px;
  z-index: 2;
  display: flex;
  justify-content: space-between;
  padding: 0 8px 0 19px;
}

.temperature-points__item {
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.2);
  position: relative;
  top: 2px;
}

.noUi-base, .noUi-connects {
  z-index: auto;
}
</style>