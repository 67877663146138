import $ from "jquery";
import noUiSlider from "nouislider";
/* import ranges from '../store/ranges.json' */
import {calcMileage} from '../data'

let ConditionsOfTravel = function () {
    $.fn.animateNumbers = function (stop, commas, duration, ease) {
        return this.each(function () {
            var $this = $(this);
            var isInput = $this.is('input');
            var start = parseInt(isInput ? $this.val().replace(/,/g, "") : $this.text().replace(/,/g, ""));
            var regex = /(\d)(?=(\d\d\d)+(?!\d))/g;
            commas = commas === undefined ? true : commas;

            // number inputs can't have commas or it blanks out
            if (isInput && $this[0].type === 'number') {
                commas = false;
            }

            $({ value: start }).animate({ value: stop }, {
                duration: duration === undefined ? 1000 : duration,
                easing: ease === undefined ? "swing" : ease,
                step: function () {
                    isInput ? $this.val(Math.floor(this.value)) : $this.text(Math.floor(this.value));
                    if (commas) {
                        isInput ? $this.val($this.val().replace(regex, "$1,")) : $this.text($this.text().replace(regex, "$1,"));
                    }
                },
                complete: function () {
                    if (parseInt($this.text()) !== stop || parseInt($this.val()) !== stop) {
                        isInput ? $this.val(stop) : $this.text(stop);
                        if (commas) {
                            isInput ? $this.val($this.val().replace(regex, "$1,")) : $this.text($this.text().replace(regex, "$1,"));
                        }
                    }
                }
            });
        });
    };

    $(document).on("click", ".tyre-slider-button", function () {
        var car = $("#car-type").val();

        var type = $("#tyre-" + car + " .swiper-slide-active").attr("data-tyre");

        $("#car-tyre").val(type);

        // $("#getkm").click();
        km();

        $(".car-item").addClass("ninja");

        $("." + car + " .tyre-" + type).removeClass("ninja")

    });


    $(".car-type").click(function () {
        var car_type = $(this).attr("data-car");

        $(".car-model").addClass("ninja");
        $("." + car_type).removeClass("ninja");

        $(".car-item").addClass("ninja");
        $("." + car_type + " .car-item:first-child").removeClass("ninja");

        $(".car-type").removeClass("active");
        $(this).addClass("active");

        $("#car-type").val(car_type);

        var type = $(this).attr("data-tyre");

        $("#car-tyre").val(type);

        $(".tyre-type-wrap").addClass("ninja");
        $("#tyre-" + car_type).removeClass("ninja");

        // var chargers_slider = new Swiper('.tyre-type-wrap', {
        //
        //     // Optional parameters
        //     direction: 'horizontal',
        //     loop: true,
        //
        //     // Navigation arrows
        //     navigation: {
        //         nextEl: '.tyre-button-next',
        //         prevEl: '.tyre-button-prev',
        //     },
        //
        // });

        // $("#getkm").click();
        km();
    });

    $(".conditioner-bt").click(function () {
        var value = $(this).attr("data-conditioner");

        $(".conditioner-bt").removeClass("active");
        $(this).addClass("active");

        $("#car-conditioner").val(value);

        km();
    });


    let temp_slider = document.getElementById('temperature-slider');

    noUiSlider.create(temp_slider, {
        start: [20],
        step: 5,
        padding: [5, 0],
        connect: true,
        range: {
            'min': [-25],
            'max': [35]
        },
    });

    $("#temperature-slider .noUi-handle").append('<div class="current-temp">20°</div>');

    temp_slider.noUiSlider.on('update', function (values, handle) {

        var temp = Math.round(values[handle]);

        $("#temperature-slider .current-temp").empty().append(temp + "°").attr("data-temp", temp);

        $("#car-temp").val(temp);
        temp === -20 ? $("#slider-start").hide() : $("#slider-start").show()
        temp === 35 ? $("#slider-end").hide() : $("#slider-end").show()

        km();
    });


    var relief_slider = document.getElementById('relief-slider');

    noUiSlider.create(relief_slider, {
        start: [0, 30, 70, 100],
        step: 5,
        connect: [false, true, true, true, true],
        range: {
            'min': [0],
            'max': [100]
        }
    });

    var connect = relief_slider.querySelectorAll('.noUi-connect');
    var classes = ['c-1-color', 'c-2-color', 'c-3-color', 'c-4-color', 'c-5-color'];

    for (var i = 0; i < connect.length; i++) {
        connect[i].classList.add(classes[i]);
    }

    $(".c-1-color").attr("id", "c-1-color");
    $(".c-2-color").attr("id", "c-2-color");
    $(".c-3-color").attr("id", "c-3-color");

    relief_slider.noUiSlider.on('update', function (values) {

        // var r_ = Math.round(values[handle]);
        var r_1_width = document.getElementById("c-1-color").getBoundingClientRect().width;

        $(".r-1-image").width(r_1_width);

        var r_2_width = document.getElementById("c-2-color").getBoundingClientRect().width;

        $(".r-2-image").width(r_2_width);

        var r_3_width = document.getElementById("c-3-color").getBoundingClientRect().width;

        $(".r-3-image").width(r_3_width);


        var city = Math.floor(values[1]);
        var highway = 100 - Math.floor(values[2]);

        var road = 100 - city - highway;

        $("#r-city").empty().append(city + "%<p>город</p>");
        $("#r-highway").empty().append(highway + "%<p>автомагистраль</p>");
        $("#r-country").empty().append(road + "%<p>загородная дорога</p>");

        $("#car-city").val(city);
        $("#car-countryroad").val(road);
        $("#car-highway").val(highway);

        km();

    });
    $('.power-reserve .embla__container').on('click', () => {
        km()
    })
    $('.vue-picker__dropdown').on('click', () => {
        setTimeout(() => {
            km()
        }, 50)
    })
    $('.btn.btn-cross-turismo').on('click', () => {
        setTimeout(() => {
            km()
        }, 50)
    })

    function searchEl(area, conditioner, temperature, tyre, carType, crossTurismo) {
        let cond = `${conditioner == 'on' ? 'AC' : conditioner == 'eco' ? 'Eco' : conditioner == 'off' ? 'Off' : ''}`
        let city = Math.round(calcMileage.city[cond][temperature] * (area.city / 100))
        let countryside = Math.round(calcMileage.countryside[cond][temperature] * (area.countryside / 100))
        let motorway = Math.round(calcMileage.motorway[cond][temperature] * (area.motorway / 100))
        let wheel = tyre.includes('19') ? 1 : tyre.includes('20') ? 1.08 : tyre.includes('21') ? 0.95 : 1;
        let carTypeCoof = carType == "taycan" ? 1 : carType == "taycan4s" ? 1 : carType == "taycanTurbo" ? 1.144 : carType == "taycanTurboS" ? 1.075 : 1
        let crossTurismoCoof = (crossTurismo == "true" && carType == "taycan") ? 1.134 : (crossTurismo == "true" && carType == "taycan4s") ? 1.137 : (crossTurismo == "true" && carType == "taycanTurbo") ? 0.966 : (crossTurismo == "true" && carType == "taycanTurboS") ? 1.026 : crossTurismo == "false" ? 1 : 1
        let summ = Math.ceil((city + countryside + motorway) * wheel * carTypeCoof * crossTurismoCoof)
        return summ
    }

    function km() {
        let crossTurismo = $("#cross-turismo-inf").val();
        let carType = $("#car-type").val();
        let conditioner = $("#car-conditioner").val();
        let temperature = $("#car-temp").val();
        let city = $("#car-city").val();
        let countryroad = $("#car-countryroad").val();
        let highway = $("#car-highway").val()
        let tyre = $("#car-tyre").val();
        let objArea = {'city': city, 'countryside': countryroad, 'motorway': highway}
        let summKM = searchEl(objArea, conditioner, temperature, tyre, carType, crossTurismo)
        $("#km").animateNumbers(summKM);
    }

    function startCalc() {
        setTimeout(() => {
            km()
        }, 100)
    }
    $(function () {
        startCalc();
    });

    // $("#getkm").click(function () {
    //
    //     var car = $("#car-type").val();
    //     var tyre = $("#car-tyre").val();
    //     var conditioner = $("#car-conditioner").val();
    //
    //     var temperature = $("#car-temp").val();
    //     var city = $("#car-city").val();
    //     var countryroad = $("#car-countryroad").val();
    //     var highway = $("#car-highway").val();
    //
    //     // var random = random = Math.floor(Math.random() * 100000000 + 100000);
    //
    //     $.ajax({
    //
    //         url: 'http://157.245.130.84:1338/ranges?model=nxps://pag/rangedata/j1-' + car + '/?language=none%26pool=root&drivingProfile_city=' + city + '&drivingProfile_countryRoad=' + countryroad + '&drivingProfile_highway=' + highway + '&temperature_value=' + temperature + '&airConditioner=' + conditioner + '&rimSelection=' + tyre + '&optionalEquipment_performancePlusBattery=false&optionalEquipment_rangeBooster=true&optionalEquipment_allSeasonRims=false',
    //         type: 'GET',
    //         success: function (data) {
    //
    //             if (data !== null) {
    //
    //                 let km = data[0];
    //                 console.log(km);
    //
    //                 $("#km").animateNumbers(km);
    //             }
    //
    //
    //         }
    //
    //     });
    //
    // });

    $("#relief-slider").click(function (e) {
        e.preventDefault();
    })
}

export { ConditionsOfTravel }