<template>
  <router-link to="/menu">
    <div class="btn-menu">
      <svg-icon name="menu-btn" height="60" width="60" class="btn-menu-svg"/>
    </div>
  </router-link>
</template>

<script>
import SvgIcon from "./IconBase";

export default {
  name: "VHeader",
  components: {SvgIcon}
}
</script>

<style scoped>

</style>